import { FC } from 'react';
import { Container } from '@chakra-ui/react';
import styles from './Loader.module.scss';

interface LoaderProps {
  height?: string;
}

const Loader: FC<LoaderProps> = ({ height }) => {
  return (
    <Container h={height} display="flex" alignItems="center" justifyContent="center">
      <div className={styles.loader}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Container>
  );
};

export default Loader;
