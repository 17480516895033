import { FC, useCallback, useMemo, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { MarkdownEditorCustom } from '@/components/common';
import CodeEditorControls from '@/components/pages/question/CodeEditorControls';
import { useUser } from '@/hooks';
import { RequestOptions } from '@/types/api';
import { COMMENT_FIELDS, DEFAULT_TEXT_EDITOR_VALUE, Operations } from '@/constants/pages';
import { CommentService, Logger, MPTracker } from '@/services';
import { Comment } from '@/types/pages/question';
import { Question, Vote } from '@/types/models';
import { MixpanelEvent, Tab } from '@/constants';

interface TextEditorTabProps
  extends Pick<Question, 'id' | 'baseQuery' | 'category' | 'difficulty' | 'company' | 'slug' | 'accessGroups'> {
  className?: string;
  currentOperation: Operations;
  setCurrentOperation: (operation: Operations) => void;
  setTabIndex: (tabIndex: number) => void;
  handleVoteAdd: (newVote: Vote) => void;
  handleCommentAdd: (newComment: Comment) => void;
  isSubmitBtnDisabled: boolean;
}

const TextEditorTab: FC<TextEditorTabProps> = (props) => {
  const {
    id: questionId,
    className,
    currentOperation,
    baseQuery,
    setCurrentOperation,
    setTabIndex,
    handleVoteAdd,
    handleCommentAdd,
    isSubmitBtnDisabled,
    category,
    difficulty,
    company,
    accessGroups,
  } = props;
  const [editorValue, setEditorValue] = useState<string>(baseQuery || DEFAULT_TEXT_EDITOR_VALUE);
  const { user } = useUser();

  const mixPanelData = useMemo(
    () => ({
      category,
      difficulty,
      company,
      accessGroups: accessGroups?.join(' ,'),
    }),
    [accessGroups, category, company, difficulty],
  );

  const handleSubmit = useCallback(async () => {
    if (!user || currentOperation === Operations.Submit) {
      return;
    }
    MPTracker.track(MixpanelEvent.ProblemSubmit, mixPanelData);

    setCurrentOperation(Operations.Submit);
    setTabIndex(Tab.QuestionDiscussion);

    try {
      const createCommentRequestOptions: RequestOptions = {
        fields: COMMENT_FIELDS,
      };
      const { comment, vote } = await CommentService.createVotedComment<Comment>(
        { questionId, body: editorValue, parentId: null },
        createCommentRequestOptions,
      );
      comment.user = user;

      handleVoteAdd(vote);
      handleCommentAdd(comment);
    } catch (error) {
      Logger.error(error);
    } finally {
      setCurrentOperation(Operations.DisabledAll);
    }
  }, [
    user,
    currentOperation,
    mixPanelData,
    setCurrentOperation,
    setTabIndex,
    questionId,
    editorValue,
    handleVoteAdd,
    handleCommentAdd,
  ]);

  const changeEditorValue = useCallback((newVal?: string) => {
    if (newVal) {
      setEditorValue(newVal);
    }
  }, []);

  return (
    <Flex overflowY="auto" overflowX="hidden" pb="16px" direction="column" className={className}>
      <MarkdownEditorCustom value={editorValue} onChange={changeEditorValue} />
      <CodeEditorControls
        onSubmit={handleSubmit}
        isSubmitLoading={currentOperation === Operations.Submit}
        isSubmitDisabled={!editorValue || isSubmitBtnDisabled}
      />
    </Flex>
  );
};

export default TextEditorTab;
