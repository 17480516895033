import { FC } from 'react';
import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useUser } from '@/hooks';
import { LoginModal } from '@/components/common';
import styles from './CodeEditorControls.module.scss';

interface CodeEditorControlsProps {
  onTestsRun?: () => void;
  onSubmit: () => void;
  isRunLoading?: boolean;
  isRunDisabled?: boolean;
  isSubmitLoading?: boolean;
  isSubmitDisabled?: boolean;
  isMobile?: boolean;
  isPythonQuestion?: boolean;
}

const CodeEditorControls: FC<CodeEditorControlsProps> = (props) => {
  const {
    onTestsRun,
    onSubmit,
    isRunLoading,
    isRunDisabled,
    isSubmitLoading,
    isSubmitDisabled,
    isMobile,
    isPythonQuestion,
  } = props;
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isCodeQuestion = Boolean(onTestsRun);

  const handleSubmit = () => {
    if (!user) {
      onOpen();
      return;
    }
    onSubmit();
  };

  return (
    <Flex
      flexDir={isMobile ? 'column' : 'row'}
      justifyContent={isCodeQuestion ? 'space-between' : 'flex-end'}
      my="10px"
      alignItems={isMobile ? 'flex-end' : 'center'}
    >
      {isCodeQuestion && (
        <Text
          w={isMobile ? '100%' : undefined}
          isTruncated
          textAlign="center"
          backgroundColor="gray.100"
          className={styles.languageVersion}
        >
          {isPythonQuestion ? 'Python' : 'PostgreSQL 14'}
        </Text>
      )}
      <Flex justifyContent="flex-end" mt={isMobile ? '10px' : undefined}>
        <LoginModal isModalOpen={isOpen} onClose={onClose} />
        {isCodeQuestion && (
          <Button
            mr="20px"
            isLoading={isRunLoading}
            isDisabled={isRunDisabled}
            colorScheme="red"
            variant="outline"
            onClick={onTestsRun}
          >
            Run Code
          </Button>
        )}
        <Button isLoading={isSubmitLoading} isDisabled={isSubmitDisabled} colorScheme="red" onClick={handleSubmit}>
          Submit
        </Button>
      </Flex>
    </Flex>
  );
};

export default CodeEditorControls;
