import { FC } from 'react';
import { Avatar as ChakraAvatar } from '@chakra-ui/react';
import { getAvatarUrl } from '@/utils';

interface AvatarProps {
  size?: string;
  userName?: string;
  avatarId?: string;
}

const Avatar: FC<AvatarProps> = ({ avatarId, userName, ...restProps }) => (
  <ChakraAvatar src={avatarId && getAvatarUrl(avatarId)} name={userName} {...restProps} />
);

export default Avatar;
