import { FC } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Button, Center, Flex, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { ProductPlanPrice } from '@/components/pages/checkout';
import { getCurrentPlanPrice, getOldPlanPrice } from '@/utils';
import { ProductPlan } from '@/types/models';
import { BillingPlanType, Routes, QueryParamKey } from '@/constants';
import OptionItem from './OptionItem';
import styles from './ProductCard.module.scss';

interface ProductCardProps {
  plan: ProductPlan;
  currentPlan: BillingPlanType | null;
}

const ProductCard: FC<ProductCardProps> = (props) => {
  const { plan, currentPlan } = props;

  const { push } = useRouter();
  const handleSubscribeClick = () => push(`${Routes.Checkout}?${QueryParamKey.Subscription}=${plan.type}`);

  const isHighlightedCard = Boolean(plan.highlightText);

  return (
    <Flex
      className={classNames({ [styles.nonHighlightedCard]: !isHighlightedCard })}
      alignItems="center"
      flexDir="column"
      border="1px solid"
      borderColor={isHighlightedCard ? 'red.500' : 'gray.200'}
      borderRadius="8px"
    >
      {isHighlightedCard && (
        <Center h="50px" borderTopLeftRadius="8px" borderTopRightRadius="8px" backgroundColor="red.500" w="100%">
          <Text fontSize="18px" fontWeight="500" color="white">
            {plan.highlightText}
          </Text>
        </Center>
      )}
      <Center h="70px" borderBottom="1px solid" borderColor="gray.200" w="100%">
        <Heading as="h2" p="4px 8px" fontSize="24px" textAlign="center">
          {plan.title}
        </Heading>
      </Center>
      <Center className={styles.cardBody}>
        <ProductPlanPrice
          currentPlanPrice={getCurrentPlanPrice(plan.type, plan.price)}
          oldPlanPrice={getOldPlanPrice(plan.oldPrice)}
          subTitle={plan.shortDescription}
        />
        <UnorderedList className={styles.optionsList} mt="22px">
          {plan.options?.map((option, index) => (
            <ListItem key={index} mb="12px" pl="16px">
              <OptionItem value={option} />
            </ListItem>
          ))}
        </UnorderedList>
      </Center>
      <Button
        isLoading={!currentPlan}
        disabled={currentPlan === plan.type}
        onClick={handleSubscribeClick}
        colorScheme="red"
        w="70%"
        mt="auto"
        mb="22px"
      >
        {currentPlan === plan.type ? 'Current plan' : 'Subscribe'}
      </Button>
    </Flex>
  );
};

export default ProductCard;
