import { FC } from 'react';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { ExpectedPythonOutput, SubmitQueryResult } from '@/types/models';
import { Submission } from '@/types/pages/question';
import { QuestionCategory, QuestionStatus } from '@/constants';
import { CodeError, PythonQueryTable, ResultQueryTable, SubmissionItem } from '@/components/pages/question';
import { AppLink, AuthInitializedOnly, Loader, Share } from '@/components/common';
import { useUser } from '@/hooks';
import { getSiteUrl } from '@/utils';
import { SignInButton } from '@clerk/nextjs';
import { handleSaveRedirectUrl } from '@/utils/handleSaveRedirectUrl';

export interface SubmissionProps {
  submissions: Submission[];
  submitResult: SubmitQueryResult | null;
  company: string | null;
  category: QuestionCategory;
  isMobile: boolean;
  isTableLoading: boolean;
  error: string | null;
  submissionsContainerClassName?: string;
  isGeneralAssemblyMvp?: boolean;
}

const Submissions: FC<SubmissionProps> = (props) => {
  const {
    submissions,
    submitResult,
    company,
    category,
    isMobile,
    error,
    isTableLoading,
    submissionsContainerClassName,
    isGeneralAssemblyMvp,
  } = props;
  const { user, isLoading } = useUser();
  const { asPath } = useRouter();
  const sharingUrl = getSiteUrl(asPath.split('?')[0]);
  const companyForHashtag = company?.split(',')[0] || '';
  const sharingText = `I just solved this ${
    companyForHashtag ? `${companyForHashtag} ` : ''
  }${category} Interview question! Think you can solve it too?`;
  const hashtags = ['DataLemur', category, companyForHashtag].filter(Boolean);

  return (
    <Flex flexDirection="column">
      {isTableLoading ? (
        <Loader height="200px" />
      ) : (
        <>
          {error ? (
            <CodeError errorMessage={error} />
          ) : (
            submitResult && (
              <Flex flexDirection="column" maxW="100%">
                {submitResult.status === QuestionStatus.Solved ? (
                  <>
                    <Text marginTop="10px" fontSize="20px" color="green">
                      Accepted
                    </Text>
                    {!isGeneralAssemblyMvp && (
                      <>
                        <Text marginTop="8px">
                          Congrats 🎉 - Share this problem, and your solution, on LinkedIn or Twitter!
                        </Text>
                        <Box margin="8px 0">
                          <Share url={sharingUrl} text={sharingText} hashtags={hashtags} isMobile={isMobile} />
                        </Box>
                        <Text>
                          In your post, don’t forget to tag Nick Singh, so that he can comment on and share your post
                          with his audience of 150k+ followers on{' '}
                          <AppLink
                            color="blue.200"
                            href="https://www.linkedin.com/in/nick-singh-tech"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LinkedIn
                          </AppLink>{' '}
                          and 25k+ followers on{' '}
                          <AppLink
                            color="blue.200"
                            href="https://twitter.com/NickSinghTech"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Twitter
                          </AppLink>{' '}
                          (which will give your post and profile more visibility)!
                        </Text>
                      </>
                    )}
                  </>
                ) : (
                  submitResult?.error && <CodeError title="Mismatched" errorMessage={submitResult?.error} />
                )}

                {submitResult.payload.isPythonQuestion ? (
                  <PythonQueryTable
                    pythonOutput={submitResult.payload.pythonCases as ExpectedPythonOutput[]}
                    status={submitResult.status}
                    marginTop="10px"
                  />
                ) : (
                  <Flex columnGap="16px" flexDirection="column" mb="26px">
                    <ResultQueryTable title="Output" table={submitResult.payload.userQueryResult} />
                    <ResultQueryTable title="Expected" table={submitResult.payload.adminQueryResult} />
                  </Flex>
                )}
              </Flex>
            )
          )}
        </>
      )}
      <AuthInitializedOnly>
        {user ? (
          submissions.length ? (
            <TableContainer w="100%">
              <Table variant="striped" colorScheme="tableOddRows">
                <Thead>
                  <Tr>
                    <Th color="gray.500">Time</Th>
                    <Th color="gray.500">Status</Th>
                    <Th color="gray.500">Your Submission</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {submissions.map((el) => (
                    <SubmissionItem key={el.id} submission={el} />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Center flexDirection="column" className={submissionsContainerClassName}>
              <Heading fontWeight="500" fontSize="18px" color="gray.400" my="20px">
                You don&apos;t have any submissions yet.
              </Heading>
            </Center>
          )
        ) : (
          <Center flexDirection="column" className={submissionsContainerClassName}>
            <Heading textAlign="center" fontWeight="500" fontSize="18px" color="gray.400" mb="26px" mt="16px">
              Sign In to view your submissions.
            </Heading>
            <SignInButton>
              <Button
                colorScheme="red"
                rightIcon={<ChevronRightIcon />}
                isDisabled={isLoading}
                onClick={() => handleSaveRedirectUrl(asPath)}
              >
                Sign in
              </Button>
            </SignInButton>
          </Center>
        )}
      </AuthInitializedOnly>
    </Flex>
  );
};

export default Submissions;
