import { FC } from 'react';
import Link from 'next/link';
import { Box, Heading, Table, TableContainer, Tbody, Text } from '@chakra-ui/react';
import { QuestionRowItem, QuestionsTableHead } from '@/components/pages/questions';
import { checkIsCodeQuestion, getQuestionStatus } from '@/utils';
import { Comment, Question, Submission } from '@/types/pages/questions';
import { Routes } from '@/constants';
import styles from './BonusQuestions.module.scss';

interface BonusQuestionsProps {
  title?: string;
  questions: Question[];
  comments: Comment[];
  submissions: Submission[];
  bonusQuestionsId?: string;
  isSubtitleVisible?: boolean;
  isLoadingSubmissions?: boolean;
}

const DEFAULT_TITLE = 'Bonus Questions';

const BonusQuestions: FC<BonusQuestionsProps> = (props) => {
  const {
    questions,
    comments,
    submissions,
    title = DEFAULT_TITLE,
    bonusQuestionsId,
    isSubtitleVisible,
    isLoadingSubmissions,
  } = props;

  return (
    <Box id={bonusQuestionsId}>
      <Heading as="h2" fontSize="40px" className={styles.title}>
        {title}
      </Heading>
      {isSubtitleVisible && (
        <Text className={styles.subTitle}>
          FYI: We&apos;re soon deprecating &apos;SQL September&apos; (September is long gone!). <br />
          But don&apos;t worry, we&apos;ve got something ✨NEW✨{' '}
          <Link
            href={Routes.StatisticsQuestions}
            className={styles.subTitleLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            for you!
          </Link>
        </Text>
      )}
      <TableContainer>
        <Table variant="striped" colorScheme="tableOddRows">
          <QuestionsTableHead />
          <Tbody>
            {questions.map((question) => (
              <QuestionRowItem
                key={question.id}
                status={getQuestionStatus(question.id, comments, submissions, !checkIsCodeQuestion(question.category))}
                question={question}
                isLoading={isLoadingSubmissions}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BonusQuestions;
