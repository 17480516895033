import { FC, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import valid from 'card-validator';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement, StripeCardNumberElementOptions } from '@stripe/stripe-js';
import { Box, Button, Center, Flex, FormControl, Heading, Input, ScaleFade, Select, Text } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { useUser } from '@/hooks';
import { Logger, MPTracker, SubscriptionService } from '@/services';
import { CardDetailsFormValues, PayAndSubscribeResponse } from '@/types/pages/checkout';
import { LoadingState } from '@/types';
import { addAccessGroup, checkIsSubscriptionActive, convertCentsToDollars } from '@/utils';
import { AccessGroup, MixpanelEvent, SOMETHING_WENT_WRONG_MESSAGE } from '@/constants';
import { ProductPlan } from '@/types/models';
import { Loader } from '@/components/common';
import SuccessesSubscribe from '../SuccessesSubscribe';
import { countryList } from './constants';
import styles from './CardDetails.module.scss';

const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().email().required(),
  holderName: Yup.string()
    .test('holderName', 'Holder name is invalid', (value) => valid.cardholderName(value).isValid)
    .required(),
  country: Yup.string().required(),
  postalCode: Yup.string()
    .test('postalCode', 'Postal code is invalid', (value) => valid.postalCode(value).isValid)
    .required(),
});

interface CardDetailsProps {
  currentPlan: ProductPlan;
}

const CardDetails: FC<CardDetailsProps> = (props) => {
  const { currentPlan } = props;

  const { user, updateUser } = useUser();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<null | string>(null);
  const [isInitialSubscriptionLoading, setIsInitialSubscriptionLoading] = useState<boolean>(true);
  const [{ loading: isSubscriptionLoading, data: subscription }, setSubscriptionState] = useState<
    LoadingState<PayAndSubscribeResponse | null>
  >({
    loading: false,
    fetched: false,
    data: null,
  });

  const isSubscriptionActive = subscription
    ? checkIsSubscriptionActive(subscription?.periodEndsAt as string, subscription.subscriptionType)
    : false;
  const stopProcessSubscription = () =>
    setSubscriptionState((prevState) => ({ ...prevState, loading: false, fetched: true }));

  const holderName = [user?.firstName, user?.lastName].filter(Boolean).join(' ').trim().toUpperCase();
  const formik = useFormik<CardDetailsFormValues>({
    initialValues: {
      email: user?.email || '',
      holderName: holderName,
      country: 'US',
      postalCode: '',
    },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      setSubscriptionState((prevState) => ({ ...prevState, loading: true }));

      if (!stripe || !elements || !user) {
        setError(SOMETHING_WENT_WRONG_MESSAGE);
        setSubscriptionState((prevState) => ({ ...prevState, loading: false, fetched: true }));
        return;
      }

      try {
        const payload = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
        });

        if (payload.error?.message || !payload.paymentMethod?.id) {
          setError(payload.error?.message || SOMETHING_WENT_WRONG_MESSAGE);
          stopProcessSubscription();
          return;
        }

        const paymentMethodId = payload.paymentMethod.id;
        const { id, clientSecret, actionRequired, isOneTimePayment, ...restData } =
          await SubscriptionService.payAndSubscribe({
            ...values,
            paymentMethodId,
            productId: currentPlan.stripeProductId,
            subscriptionType: currentPlan.type,
          });

        if (actionRequired && clientSecret) {
          const { paymentIntent, error: paymentIntentError } = await stripe.confirmCardPayment(clientSecret);

          if (paymentIntentError?.message) {
            setError(paymentIntentError.message);
            stopProcessSubscription();
            return;
          }

          if (paymentIntent?.status !== 'succeeded' || !id) {
            setError(SOMETHING_WENT_WRONG_MESSAGE);
            stopProcessSubscription();
            return;
          }

          const confirmedSubscription = await SubscriptionService.checkSubscription({ id, isOneTimePayment });

          setSubscriptionState({ data: confirmedSubscription, loading: false, fetched: true });
        } else {
          setSubscriptionState({
            data: { id, clientSecret, actionRequired, isOneTimePayment, ...restData },
            loading: false,
            fetched: true,
          });
        }

        MPTracker.track(MixpanelEvent.Subscribe, { userId: user.id, subscriptionType: currentPlan.type });
        const updatedAccessGroup = addAccessGroup(user?.accessGroups, AccessGroup.PremiumQuestions);

        if (user.accessGroups === updatedAccessGroup) {
          return;
        }

        updateUser({ accessGroups: updatedAccessGroup });
      } catch (e: any) {
        setError(e.response.data.error || SOMETHING_WENT_WRONG_MESSAGE);
        setSubscriptionState((prevState) => ({ ...prevState, loading: false, fetched: true }));
        Logger.error(e);
      }
    },
  });

  const isButtonDisabled = Boolean(error) || !formik.isValid || isSubscriptionLoading;

  const options = useMemo(
    (): StripeCardNumberElementOptions => ({
      showIcon: true,
      disabled: isSubscriptionLoading,
      classes: {
        base: styles.stripeInput,
        invalid: styles.stripeInputInvalid,
        focus: styles.stripeInputFocus,
      },
    }),
    [isSubscriptionLoading],
  );
  const handleFormChange = () => {
    setError(null);
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const lastSubscriptionFilter = {
      filter: { user_id: { _eq: user.id } },
    };

    (async () => {
      try {
        const [lastSubscription] = await SubscriptionService.getLastSubscription(lastSubscriptionFilter);

        setSubscriptionState({ loading: false, fetched: true, data: lastSubscription });
      } catch (e: any) {
        Logger.error(e);
        stopProcessSubscription();
      }
      setIsInitialSubscriptionLoading(false);
    })();
  }, [user]);

  const payClickEvent = () => {
    MPTracker.track(MixpanelEvent.ClickedPay, { userId: user?.id });
  };

  return (
    <Flex flexDir="column" p="20px" pb="10px" border="1px solid" borderColor="gray.200" borderRadius="8px">
      {isInitialSubscriptionLoading ? (
        <Loader height="500px" />
      ) : (
        <>
          {isSubscriptionActive ? (
            <SuccessesSubscribe />
          ) : (
            <>
              <Heading as="h3" mb="20px" fontSize="24px">
                Payment Information
              </Heading>
              <form className={styles.form} onSubmit={formik.handleSubmit} onChange={handleFormChange} name="checkout">
                <FormControl isDisabled={isSubscriptionLoading} isInvalid={Boolean(formik.errors.email)}>
                  <Input
                    className={styles.input}
                    focusBorderColor="blue.300"
                    name="email"
                    type="search"
                    placeholder="Email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    _disabled={{ color: 'gray.300' }}
                  />
                </FormControl>
                <Heading as="h3" fontSize="24px" my="20px">
                  Card Details
                </Heading>
                <FormControl isDisabled={isSubscriptionLoading} isInvalid={Boolean(formik.errors.holderName)} mb="10px">
                  <Input
                    className={styles.input}
                    focusBorderColor="blue.300"
                    name="holderName"
                    type="search"
                    placeholder="Holder name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.holderName}
                    _disabled={{ color: 'gray.300' }}
                  />
                </FormControl>
                <FormControl mb="10px">
                  <CardNumberElement options={options} onChange={handleFormChange} />
                </FormControl>
                <FormControl isDisabled={isSubscriptionLoading} mb="10px">
                  <CardExpiryElement options={options} onChange={handleFormChange} />
                </FormControl>
                <FormControl isDisabled={isSubscriptionLoading} mb="10px">
                  <CardCvcElement options={options} onChange={handleFormChange} />
                </FormControl>
                <FormControl isDisabled={isSubscriptionLoading} isInvalid={Boolean(formik.errors.country)} mb="10px">
                  <Select
                    className={styles.input}
                    focusBorderColor="blue.300"
                    name="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    _disabled={{ color: 'gray.300' }}
                  >
                    {countryList.map((country, index) => (
                      <option key={index} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl isDisabled={isSubscriptionLoading} isInvalid={Boolean(formik.errors.postalCode)} mb="10px">
                  <Input
                    className={styles.input}
                    focusBorderColor="blue.300"
                    name="postalCode"
                    type="number"
                    placeholder="ZIP"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.postalCode}
                    _disabled={{ color: 'gray.300' }}
                  />
                </FormControl>
                {error && (
                  <ScaleFade in={Boolean(error)}>
                    <Center backgroundColor="red.100" borderRadius="8px" py="20px" px="32px" my="10px">
                      <WarningIcon mr="10px" fontSize="24px" />
                      <Text>{error}</Text>
                    </Center>
                  </ScaleFade>
                )}
                <Button
                  disabled={isButtonDisabled}
                  isLoading={isSubscriptionLoading}
                  className={styles.submitButton}
                  type="submit"
                  colorScheme="red"
                  onClick={() => payClickEvent()}
                >
                  Pay {`$${convertCentsToDollars(currentPlan.price)}`}
                </Button>
                <Box alignSelf="flex-end" mt="12px">
                  <Image src="/secure-stripe-payment-logo.png" alt="secure stripe payment" width={300} height={45} />
                </Box>
              </form>
            </>
          )}
        </>
      )}
    </Flex>
  );
};
export default CardDetails;
