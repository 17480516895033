import { AnchorHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { InView } from 'react-intersection-observer';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
// @ts-ignore
import remarkHeadingId from 'remark-heading-id';
import type { Element } from 'hast';
import { As, Heading } from '@chakra-ui/react';
import { MarkdownCodeBlock } from '@/components/common';
import { YOUTUBE_SHORT_URL, YOUTUBE_URL } from '@/constants';
import { useBlogStore } from '@/stores';
import 'katex/dist/katex.min.css';
import styles from './Markdown.module.scss';

interface MarkdownProps extends ReactMarkdownOptions {
  isBlog?: boolean;
  className?: string;
}

const DynamicCodeBlock = dynamic(() => Promise.resolve(MarkdownCodeBlock), {
  ssr: false,
});

function MarkdownTableWrapper<T>({ children }: { children: ReactNode } & T) {
  return (
    <div style={{ overflowX: 'auto', marginBottom: '10px' }}>
      <table>{children}</table>
    </div>
  );
}

type MarkdownLinkProps = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

const MarkdownLink: FC<MarkdownLinkProps> = (props) => {
  const { href, children } = props;

  if (!href) {
    return <p>{children}</p>;
  }

  const isYouTubeLink = href.includes(YOUTUBE_URL) || href.includes(YOUTUBE_SHORT_URL);

  if (isYouTubeLink) {
    return <ReactPlayer url={href} controls width="100%" style={{ maxWidth: '640px', padding: '20px 0' }} />;
  }

  const shouldOpenInNewTab: boolean = href.includes('http');
  const target = shouldOpenInNewTab ? '_blank' : '_self';
  const rel = shouldOpenInNewTab ? 'noopener noreferrer' : undefined;

  return (
    <Link href={href} passHref target={target} rel={rel}>
      {children}
    </Link>
  );
};

function InViewHeading<T>(
  props: {
    children: ReactNode;
    node: Element;
    id?: string;
  } & T,
) {
  const { titleIds, setTitleIds, activeId, setActiveId } = useBlogStore();
  const tagName = props.node?.tagName as As;

  if (!props.id) {
    return (
      <Heading as={tagName} {...props}>
        {props.children}
      </Heading>
    );
  }

  return (
    <InView
      threshold={1}
      onChange={(inView, entry) => {
        const { id } = entry.target;
        if (activeId === id && !inView && titleIds.includes(id)) {
          setActiveId(null);
        }

        setTitleIds(id, inView, entry.boundingClientRect.top);
      }}
    >
      {(data) => {
        return (
          <Heading as={tagName} ref={data.ref} {...props}>
            {props.children}
          </Heading>
        );
      }}
    </InView>
  );
}

const Markdown: FC<MarkdownProps> = ({ className, children }) => {
  return (
    <ReactMarkdown
      className={classNames(styles.reactMarkdown, className)}
      remarkPlugins={[remarkGfm, remarkMath, remarkHeadingId]}
      rehypePlugins={[rehypeKatex]}
      components={{
        a: ({ href, children: linkChildren }) => <MarkdownLink href={href}>{linkChildren}</MarkdownLink>,
        code: DynamicCodeBlock,
        table: MarkdownTableWrapper,
        // @ts-ignore
        h1: InViewHeading,
        // @ts-ignore
        h2: InViewHeading,
        // @ts-ignore
        h3: InViewHeading,
        // @ts-ignore
        h4: InViewHeading,
        // @ts-ignore
        h5: InViewHeading,
        // @ts-ignore
        h6: InViewHeading,
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
