import { FC } from 'react';
import { useRouter } from 'next/router';
import { Button, Center, Flex, Heading, Text } from '@chakra-ui/react';
import { ChevronRightIcon, LockIcon } from '@chakra-ui/icons';
import { CompanyTag, Loader, Markdown, Share } from '@/components/common';
import { getDifficultyColor } from '@/components/pages/questions/QuestionRowItem/helpers';
import { Hints } from '@/components/pages/question';
import { getSiteUrl } from '@/utils';
import { Question } from '@/types/pages/question';
import { Hint } from '@/types/models';
import { Routes } from '@/constants';

type QuestionDetailsProps = Pick<Question, 'description' | 'difficulty' | 'company' | 'accessDenied' | 'category'> & {
  hints: Hint[];
  isMobile: boolean;
  isQuestionLoading: boolean;
  isGeneralAssemblyMvp?: boolean;
};

const QuestionDetails: FC<QuestionDetailsProps> = (props) => {
  const {
    description,
    difficulty,
    company,
    category,
    hints,
    accessDenied,
    isQuestionLoading,
    isGeneralAssemblyMvp,
    isMobile,
  } = props;

  const { asPath, push } = useRouter();
  const handleSubscribeClick = () => push(Routes.Pricing);
  const areHintsEmpty = !hints.length;
  const sharingUrl = getSiteUrl(asPath.split('?')[0]);
  const companyForHashtag = company?.split(',')[0] || '';
  const hashtags = ['DataLemur', category, companyForHashtag].filter(Boolean);

  return (
    <>
      <Flex mb="12px" alignItems="center" flexWrap="wrap">
        {!isGeneralAssemblyMvp && (
          <>
            <Text fontSize="16px" py="10px" mr="24px" color={getDifficultyColor(difficulty)}>
              {difficulty}
            </Text>
            {company && <CompanyTag companies={company} isLink />}
            <Share url={sharingUrl} hashtags={hashtags} isMobile={isMobile} />
          </>
        )}
      </Flex>
      {isQuestionLoading ? (
        <Loader height="500px" />
      ) : (
        <>
          {accessDenied ? (
            <Center flexDir="column" w="100%" h="100%" minH="300px" color="gray.500">
              <LockIcon fontSize="36px" mb="16px" color="red.500" />
              <Heading mb="10px" textAlign="center" fontWeight="500" fontSize="18px">
                Subscribe to unlock.
              </Heading>
              <Heading maxW="400px" mb="30px" textAlign="center" fontWeight="500" fontSize="16px">
                Thanks for using DataLemur! To view this question you must subscribe to premium.
              </Heading>
              <Button onClick={handleSubscribeClick} autoFocus colorScheme="red" rightIcon={<ChevronRightIcon />}>
                Subscribe
              </Button>
            </Center>
          ) : (
            <>
              {description && <Markdown>{description}</Markdown>}
              {!areHintsEmpty && <Hints hints={hints} />}
            </>
          )}
        </>
      )}
    </>
  );
};

export default QuestionDetails;
