import React, { FC } from 'react';
import Link from 'next/link';
import * as querystring from 'query-string';
import { Flex, Text } from '@chakra-ui/react';
import { Routes } from '@/constants';

interface CompaniesProps {
  companies: string;
  isLink?: boolean;
}

interface CompanyTagProps {
  companyName: string;
  shouldTruncate?: boolean;
}

const CompanyTag: FC<CompanyTagProps> = ({ companyName, shouldTruncate = false }) => {
  const truncatedStyle = shouldTruncate
    ? {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxW: { base: '90px', md: '100%' },
      }
    : '';

  return (
    <Text
      bg="blue.100"
      display="inline"
      py="4px"
      px="8px"
      my="4px"
      mr="8px"
      ml="0"
      borderRadius="14px"
      maxW="100%"
      sx={{
        ...truncatedStyle,
      }}
    >
      {companyName.trim()}
    </Text>
  );
};

const Companies: FC<CompaniesProps> = ({ companies, isLink }) => (
  <Flex flexWrap="wrap">
    {companies.split(',').map((company) =>
      isLink ? (
        <Link
          key={company}
          href={`${Routes.Questions}?${querystring.stringify({ company })}`}
          style={{ margin: '4px 0' }}
        >
          <CompanyTag companyName={company} />
        </Link>
      ) : (
        <CompanyTag key={company} companyName={company} shouldTruncate />
      ),
    )}
  </Flex>
);

export default Companies;
