import { FC } from 'react';
import { ExternalLinks, Routes, QuestionCategory } from '@/constants';
import { AppLink } from '@/components/common';

interface HeadingSubtitleProps {
  questionsCategory: QuestionCategory;
  isMobile: boolean;
  isCommonQuestionsPage?: boolean;
}

const DataScienceCourseLink = () => (
  <AppLink
    href={ExternalLinks.AceTheDataScienceNineDaysCourse}
    textDecor="underline"
    target="_blank"
    rel="noopener noreferrer"
  >
    9-Day Interview Crash Course!
  </AppLink>
);

const HeadingSubtitle: FC<HeadingSubtitleProps> = (props) => {
  const { isCommonQuestionsPage, questionsCategory, isMobile } = props;

  if (isCommonQuestionsPage) {
    return (
      <>
        Practice the most common SQL, Statistics, ML, and Python questions asked in FAANG Data Science & Data Analyst
        interviews!
      </>
    );
  }

  switch (questionsCategory) {
    case QuestionCategory.SQL:
      return (
        <>
          Writing SQL queries is KEY to Acing the Data Science Interview, but if you want to go beyond SQL join our FREE
          {isMobile ? ' ' : <br />}
          <DataScienceCourseLink /> (which covers Probability, Statistics, ML, Coding, & Product-Sense).
        </>
      );
    case QuestionCategory.MachineLearning:
      return (
        <>
          Data Scientists and ML Engineers can expect ML interview questions that cover linear and logistic regression,
          random forests, PCA, and neural networks. ML Interviews also cover model training, evaluation, and model
          deployment. FAANG companies also ask{' '}
          <AppLink href={Routes.StatisticsQuestions} textDecor="underline" target="_blank" rel="noopener noreferrer">
            statistics interview questions
          </AppLink>{' '}
          to make sure your ML knowledge is built on a solid foundation.
        </>
      );
    case QuestionCategory.Statistics:
      return (
        <>
          Data Science interviews and Data Analysts interviews go WAY beyond just SQL questions.
          {isMobile ? ' ' : <br />}Try these ✨NEW✨ Statistics Interview questions, and be one of the first people to
          share your solution in the discussion section!
        </>
      );
    case QuestionCategory.Python:
      return (
        <>
          Practice Python interview questions which test Data Structures knowledge. We curated this list to only include
          frequently asked coding questions for FAANG Data Science jobs (not SWE jobs).
        </>
      );
    case QuestionCategory.TheOfficeSQL:
      return (
        <>Practice SQL by analyzing data from Dunder Mifflin, Scott&apos;s Tots, and the Michael Scott Paper Company.</>
      );
    default:
      return <></>;
  }
};

export default HeadingSubtitle;
