import { FC } from 'react';
import { Td, Tr } from '@chakra-ui/react';

interface EmptyTableRowProps {
  text: string;
}

const EmptyTableRow: FC<EmptyTableRowProps> = ({ text }) => (
  <Tr>
    <Td></Td>
    <Td maxWidth="130px" color="gray.600">
      {text}
    </Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
  </Tr>
);

export default EmptyTableRow;
