import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Wrap } from '@chakra-ui/react';
import MarkdownEditorCustom from '@/components/common/MarkdownEditorCustom';
import { Operations } from '@/components/pages/question/DiscussionComment/DiscussionComment';
import { debounce } from '@/utils';

interface ReplyInputProps {
  defaultReplyValue: string;
  handleReplySubmit: (newReplyValue: string) => void;
  currentOperation: Operations;
  replyValueCacheKey: string;
}

const ReplyCommentInput: FC<ReplyInputProps> = ({
  defaultReplyValue,
  handleReplySubmit,
  currentOperation,
  replyValueCacheKey,
}) => {
  const [replyCommentValue, setReplyCommentValue] = useState(defaultReplyValue);
  const isSubmitBtnDisabled = replyCommentValue.length === defaultReplyValue.length;

  const saveReplyValueToCache = useMemo(
    () =>
      debounce((value: string) => {
        localStorage.setItem(replyValueCacheKey, value);
      }, 1000),
    [replyValueCacheKey],
  );

  const handleInputChange = (newVal?: string) => {
    if (typeof newVal === 'string') {
      setReplyCommentValue(newVal);
      saveReplyValueToCache(newVal);
    }
  };

  useEffect(() => {
    const savedReplyInput = localStorage.getItem(replyValueCacheKey);
    if (savedReplyInput) {
      setReplyCommentValue(savedReplyInput);
    }
  }, [replyValueCacheKey]);

  return (
    <Wrap mt={'8px'} ml="20px" mr="4px">
      <MarkdownEditorCustom value={replyCommentValue} onChange={handleInputChange} />
      <Button
        isDisabled={isSubmitBtnDisabled}
        onClick={() => handleReplySubmit(replyCommentValue)}
        colorScheme="red"
        variant="outline"
        marginTop="10px"
        isLoading={currentOperation === Operations.ReplyProcessing}
        loadingText="Submitting..."
      >
        Submit
      </Button>
    </Wrap>
  );
};

export default ReplyCommentInput;
