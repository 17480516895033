import { FC, ReactNode } from 'react';
import { Routes } from '@/constants';
import { AppLink } from '@/components/common';

interface TableLinkProps {
  isCentered?: boolean;
  questionSlug: string;
  isGeneralAssemblyMvp?: boolean;
  children: ReactNode;
}

const TableLink: FC<TableLinkProps> = ({ questionSlug, isCentered = false, children, isGeneralAssemblyMvp }) => {
  const linkPath = isGeneralAssemblyMvp
    ? Routes.GeneralAssemblyQuestion
    : Routes.Question.replace('{questionSlug}', questionSlug);

  const isContentCentered = isCentered ? { justifyContent: 'center' } : '';

  return (
    <AppLink
      href={linkPath}
      display="flex"
      alignItems="center"
      h="100%"
      w="100%"
      py="0"
      px={{ base: '12px', md: '24px' }}
      _hover={{ textDecor: 'none' }}
      sx={{
        ...isContentCentered,
      }}
    >
      {children}
    </AppLink>
  );
};

export default TableLink;
