import { FC } from 'react';
import Link from 'next/link';
import { Button, Container, Text } from '@chakra-ui/react';
import Layout from '@/components/layout';
import { Routes } from '@/constants';

const AppErrorFallback: FC = () => (
  <Layout tabTitle="Error">
    <Container pt="50px" textAlign="center" fontSize="24px" flexDir="column" alignItems="center">
      <Text mb="24px">Something went wrong :\</Text>
      <Text>Please try to reload page</Text>
      <Text my="8px">or</Text>
      <Link href={Routes.Questions} passHref legacyBehavior>
        <Button as="a" colorScheme="red">
          Go to questions
        </Button>
      </Link>
    </Container>
  </Layout>
);

export default AppErrorFallback;
