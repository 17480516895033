import { FC } from 'react';
import { Code, Flex, FlexProps, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface CodeErrorProps extends Omit<FlexProps, 'title'> {
  title?: string;
  errorMessage: string;
  hideError?: () => void;
  shouldHideError?: boolean;
}

const CodeError: FC<CodeErrorProps> = ({
  title = 'Error',
  errorMessage,
  hideError,
  shouldHideError = false,
  ...containerProps
}) => (
  <Flex direction="column" {...containerProps}>
    {shouldHideError ? (
      <Flex
        pl="6px"
        pr="8px"
        mt="10px"
        h="26px"
        color="red"
        borderRadius="8px"
        justifyContent="space-between"
        alignItems="center"
        onClick={hideError}
        _hover={{
          cursor: 'pointer',
          backgroundColor: 'gray.200',
        }}
      >
        <Text fontSize="20px">{title}</Text>
        <ChevronDownIcon fontSize="20px" />
      </Flex>
    ) : (
      <Text marginTop="10px" fontSize="20px" color="red">
        {title}
      </Text>
    )}

    <Code marginTop="10px" colorScheme="red">
      {errorMessage}
    </Code>
  </Flex>
);

export default CodeError;
