import { FC, useCallback } from 'react';
import { Table, TableContainer, Tbody } from '@chakra-ui/react';
import { Comment, Question, Submission } from '@/types/pages/questions';
import { EmptyTableRow, QuestionRowItem, QuestionsTableHead } from '@/components/pages/questions';
import { checkIsCodeQuestion, getQuestionStatus } from '@/utils';
import { useRouter } from 'next/router';
import { useQuestionsStore } from '@/stores';

interface QuestionsTableProps {
  questions: Question[];
  isLoadingSubmissions: boolean;
  comments: Comment[];
  submissions: Submission[];
  isCommonQuestionsPage?: boolean;
  isGeneralAssemblyMvp?: boolean;
}

const QuestionsTable: FC<QuestionsTableProps> = (props) => {
  const { questions, isLoadingSubmissions, comments, submissions, isCommonQuestionsPage, isGeneralAssemblyMvp } = props;
  const isTableEmpty = !questions.length;

  const { setFilterParams } = useQuestionsStore();
  const { query } = useRouter();

  const cacheFilterParams = useCallback(
    () => (Object.keys(query).length ? setFilterParams(query) : undefined),
    [query, setFilterParams],
  );

  return (
    <TableContainer>
      <Table variant="striped" colorScheme="tableOddRows">
        <QuestionsTableHead isCommonQuestionsPage={isCommonQuestionsPage} isGeneralAssemblyMvp={isGeneralAssemblyMvp} />
        <Tbody>
          {isTableEmpty ? (
            <EmptyTableRow text="There are no such questions..." />
          ) : (
            questions.map((question) => (
              <QuestionRowItem
                key={question.id}
                status={getQuestionStatus(question.id, comments, submissions, !checkIsCodeQuestion(question.category))}
                isLoading={isLoadingSubmissions}
                question={question}
                isCommonQuestionsPage={isCommonQuestionsPage}
                isGeneralAssemblyMvp={isGeneralAssemblyMvp}
                onClick={cacheFilterParams}
              />
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default QuestionsTable;
