import { FC } from 'react';
import Link from 'next/link';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { footerLinks } from './footerLinks';
import styles from './Footer.module.scss';

const Footer: FC = () => (
  <Flex justifyContent="center" bg="gray.100" pb="24px" pt="34px" fontSize="12px" mt="auto">
    <Box className={styles.wrapper}>
      {footerLinks.map((column, index) => (
        <Flex key={index} direction="column" mx="6px">
          <Heading as="h3" className={styles.columnTitle}>
            {column.title}
          </Heading>
          {column.links.map(({ text, href, inNewTab }, linkIndex) => (
            <Link
              key={linkIndex}
              href={href}
              className={styles.link}
              target={inNewTab ? '_blank' : undefined}
              rel="noopener noreferrer"
            >
              {text}
            </Link>
          ))}
        </Flex>
      ))}
    </Box>
  </Flex>
);

export default Footer;
