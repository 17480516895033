import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Center, Flex, Heading, Text } from '@chakra-ui/react';
import classNames from 'classnames';
import { Tag } from '@/types/models';
import styles from './TagsSection.module.scss';

interface TagForDisplay extends Tag {
  questionsAmount: number;
}

interface TagsSectionProps {
  tags: TagForDisplay[];
  selectedTagIds: string[];
  setSelectedTagIds: Dispatch<SetStateAction<string[]>>;
}

const TagsSection: FC<TagsSectionProps> = (props) => {
  const { tags, setSelectedTagIds, selectedTagIds } = props;

  const handleTagSelect = useCallback(
    (tagId: string): void => {
      setSelectedTagIds((prevState) => {
        if (prevState.includes(tagId)) {
          return prevState.filter((id) => id !== tagId);
        }
        return [...prevState, tagId];
      });
    },
    [setSelectedTagIds],
  );

  return (
    <Center flexDir="column" w="350px" mt="20px">
      <Heading as="h3" alignSelf="baseline" variant="secondary" mb="18px">
        Tags
      </Heading>
      <Flex
        className={styles.tagsContainer}
        p="10px 0 10px 4px"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="20px"
      >
        {tags.map(({ id, title, questionsAmount }) => {
          const isTagSelected = selectedTagIds?.includes(id);
          return (
            <Text
              className={classNames(styles.tag, {
                [styles.selectedTag]: isTagSelected,
              })}
              key={id}
              onClick={() => handleTagSelect(id)}
              color="black.100"
            >
              {title}
              <span
                className={classNames(styles.tagAmount, {
                  [styles.selectedAmount]: isTagSelected,
                })}
              >
                {questionsAmount}
              </span>
            </Text>
          );
        })}
      </Flex>
    </Center>
  );
};

export default TagsSection;
