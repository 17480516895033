import { FC, useMemo } from 'react';
import { Flex, Heading as HeadingChakra, Text } from '@chakra-ui/react';

import { MAP_QUESTION_CATEGORY_TO_TEXT, QuestionCategory } from '@/constants';
import HeadingSubtitle from './HeadingSubtitle';

interface QuestionsHeadingProps {
  questionsCategory: QuestionCategory;
  isMobile: boolean;
  isGeneralAssemblyMvp?: boolean;
  isCommonQuestionsPage?: boolean;
}

const TITLE_POSTFIX = 'Interview Questions';

const getTitle = (category: QuestionCategory, isCommonQuestionsPage?: boolean, isGeneralAssembly?: boolean): string => {
  if (isGeneralAssembly) {
    return `General Assembly ${TITLE_POSTFIX}`;
  }

  if (isCommonQuestionsPage) {
    return `SQL & Data ${TITLE_POSTFIX}`;
  }

  if (category === QuestionCategory.Statistics) {
    return `${MAP_QUESTION_CATEGORY_TO_TEXT[category]} ${TITLE_POSTFIX} [Beta Test]`;
  }

  if (category === QuestionCategory.Python) {
    return `${MAP_QUESTION_CATEGORY_TO_TEXT[category]} Data Structures ${TITLE_POSTFIX} for Data Scientists`;
  }

  if (category === QuestionCategory.TheOfficeSQL) {
    return `${MAP_QUESTION_CATEGORY_TO_TEXT[category]} Questions`;
  }

  return `${MAP_QUESTION_CATEGORY_TO_TEXT[category]} ${TITLE_POSTFIX}`;
};

const QuestionsHeading: FC<QuestionsHeadingProps> = (props) => {
  const { questionsCategory, isCommonQuestionsPage, isGeneralAssemblyMvp, isMobile } = props;

  const title = useMemo(
    () => getTitle(questionsCategory, isCommonQuestionsPage, isGeneralAssemblyMvp),
    [isCommonQuestionsPage, isGeneralAssemblyMvp, questionsCategory],
  );

  return (
    <Flex flexDirection="column">
      <HeadingChakra as="h1" variant="main" fontWeight="700" my="20px" mx="0">
        {title}
      </HeadingChakra>
      {!isGeneralAssemblyMvp && (
        <Text fontFamily="KumbhSans" maxW="1000px" mb="22px">
          <HeadingSubtitle
            questionsCategory={questionsCategory}
            isCommonQuestionsPage={isCommonQuestionsPage}
            isMobile={isMobile}
          />
        </Text>
      )}
    </Flex>
  );
};

export default QuestionsHeading;
