import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Tab as CTab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from '@chakra-ui/react';
import { DiscussionTab, QuestionDetails, Solution, Submissions } from '@/components/pages/question';
import { Loader, LoginModal, SubscribeModal } from '@/components/common';
import { Comment, Question, Submission } from '@/types/pages/question';
import { Hint, SubmitQueryResult, Vote } from '@/types/models';
import { useInfiniteScroll, useUser } from '@/hooks';
import { Logger, VotesService } from '@/services';
import { checkIsCodeQuestion, compareComments, getDefaultCommentText } from '@/utils';
import { LoadingState } from '@/types';
import { CommentsFilter, GeneralAssemblyQuestionTab, Tab } from '@/constants';
import styles from '@/styles/pages/QuestionTabs.module.scss';

interface QuestionTabsProps {
  isDiscussionTabActive: boolean;
  tabIndex: Tab | GeneralAssemblyQuestionTab;
  setTabIndex: (index: Tab) => void;
  question: Question;
  areSubmissionsFetched: boolean;
  submissions: Submission[];
  isSubmitTableLoading: boolean;
  isTextQuestionLoading: boolean;
  submitTableError: string | null;
  submitResult: SubmitQueryResult | null;
  hints: Hint[];
  currentComments: Comment[];
  myComments: Comment[];
  getMoreComments: () => void;
  isLoadingComments: boolean;
  commentsFilter: CommentsFilter;
  hasMoreComments: boolean;
  setCommentsFilter: Dispatch<SetStateAction<CommentsFilter>>;
  votesState: LoadingState<Vote[]>;
  setVotesState: Dispatch<SetStateAction<LoadingState<Vote[]>>>;
  isMobile: boolean;
  onCommentAdd: (comment: Comment) => void;
  onCommentDelete: (commentId: string) => void;
  onVoteAdd: (newVote: Vote) => void;
  isQuestionLoading: boolean;
  isGeneralAssemblyMvp?: boolean;
}

const QuestionTabs: FC<QuestionTabsProps> = (props) => {
  const {
    isDiscussionTabActive,
    tabIndex,
    setTabIndex,
    question,
    areSubmissionsFetched,
    submissions,
    isSubmitTableLoading,
    isTextQuestionLoading,
    submitResult,
    submitTableError,
    hints,
    currentComments,
    myComments,
    getMoreComments,
    isLoadingComments,
    commentsFilter,
    setCommentsFilter,
    hasMoreComments,
    votesState,
    setVotesState,
    isMobile,
    onCommentAdd,
    onCommentDelete,
    onVoteAdd,
    isQuestionLoading,
    isGeneralAssemblyMvp,
  } = props;
  const { user, isLoading, isAuthInitialized } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { loading: areVotesLoading, fetched: areVotesFetched, data: votes } = votesState;
  const isCodeQuestion = checkIsCodeQuestion(question.category);
  const currentReplies = useMemo(() => currentComments?.filter((comment) => comment.parentId), [currentComments]) || [];
  const defaultCommentText = useMemo(() => getDefaultCommentText(question.category), [question.category]);

  const validateComments = useCallback(
    (comments: Comment[]) => {
      return (
        comments?.filter(
          (comment) =>
            !comment.parentId &&
            !defaultCommentText.includes(comment.body) &&
            !question.baseQuery?.includes(comment.body),
        ) || []
      );
    },
    [defaultCommentText, question.baseQuery],
  );

  const commentsValidated = useMemo(() => validateComments(currentComments), [currentComments, validateComments]);

  const myCommentsValidated = useMemo(() => validateComments(myComments), [myComments, validateComments]);

  const filteredComments: Comment[] = useMemo(() => {
    if (!commentsValidated && !myCommentsValidated) {
      return [];
    }

    myCommentsValidated.sort(
      (com, nextCom) => new Date(nextCom.createdAt).getTime() - new Date(com.createdAt).getTime(),
    );

    const commentsWithoutLastUserComment = myCommentsValidated.length
      ? commentsValidated.filter(({ id }) => id !== myCommentsValidated[0].id)
      : commentsValidated;

    if (commentsFilter === CommentsFilter.MostVotes) {
      return [
        ...myCommentsValidated.sort((com, nextCom) => compareComments(com, nextCom)),
        ...commentsWithoutLastUserComment.sort((com, nextCom) => compareComments(com, nextCom)),
      ];
    }

    if (commentsFilter === CommentsFilter.OldestToNewest) {
      return [
        ...myCommentsValidated.sort(
          (com, nextCom) => new Date(com.createdAt).getTime() - new Date(nextCom.createdAt).getTime(),
        ),
        ...commentsWithoutLastUserComment.sort(
          (com, nextCom) => new Date(com.createdAt).getTime() - new Date(nextCom.createdAt).getTime(),
        ),
      ];
    }

    return [
      ...myCommentsValidated,
      ...commentsWithoutLastUserComment.sort(
        (com, nextCom) => new Date(nextCom.createdAt).getTime() - new Date(com.createdAt).getTime(),
      ),
    ];
  }, [commentsValidated, myCommentsValidated, commentsFilter]);

  const handleChangeTab = useCallback(
    (index: Tab) => {
      if (user && !question.accessDenied) {
        setTabIndex(index);
      }
    },
    [setTabIndex, user, question.accessDenied],
  );
  const handleTabClick = useCallback(() => {
    if (!user || question.accessDenied) {
      onOpen();
    }
  }, [onOpen, user, question.accessDenied]);

  const commentSectionBottom = useInfiniteScroll(getMoreComments);

  useEffect(() => {
    if (!isAuthInitialized || areVotesLoading || areVotesFetched || !currentComments || question.accessDenied) {
      return;
    }
    if (!user?.id) {
      setVotesState({
        loading: false,
        fetched: true,
        data: [],
      });
      return;
    }

    (async () => {
      setVotesState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      try {
        let fetchedVotes: Vote[] = [];

        if (currentComments && currentComments.length > 0) {
          fetchedVotes = await VotesService.getVotes({
            fields: ['id', 'outcome', 'comment_id', 'user_id'],
            filter: {
              user_id: { _eq: user?.id },
              comment_id: { _in: currentComments?.map((comment) => comment.id) },
            },
          });
        }

        setVotesState({
          loading: false,
          fetched: true,
          data: fetchedVotes,
        });
      } catch (error) {
        Logger.error(error);
        setVotesState({
          loading: false,
          fetched: true,
          data: [],
        });
      }
    })();
  }, [
    isAuthInitialized,
    areVotesLoading,
    areVotesFetched,
    user,
    currentComments,
    setVotesState,
    question.accessDenied,
  ]);

  return (
    <Tabs
      className={classNames(styles.questionTabs, {
        [styles.questionTabsFullWidth]: isDiscussionTabActive,
        [styles.questionMobileView]: isMobile,
      })}
      index={tabIndex}
      variant="enclosed"
      onChange={handleChangeTab}
      display="flex"
    >
      {question.accessDenied ? (
        <SubscribeModal isModalOpen={isOpen} onClose={onClose} />
      ) : (
        <LoginModal isModalOpen={isOpen} onClose={onClose} />
      )}
      <TabList overflowY="hidden" overflowX="auto" flexShrink={0}>
        <CTab>Description</CTab>
        {!isGeneralAssemblyMvp && (
          <>
            <CTab ml="6px" onClick={handleTabClick} isDisabled={isLoading}>
              Solution
            </CTab>
            <CTab ml="6px" onClick={handleTabClick} isDisabled={isLoading || isLoadingComments}>
              Discussion
            </CTab>
          </>
        )}
        {isCodeQuestion && (
          <CTab ml="6px" onClick={handleTabClick} isDisabled={isLoading}>
            Submissions
          </CTab>
        )}
      </TabList>

      <TabPanels overflowY="auto">
        <TabPanel>
          <QuestionDetails
            {...question}
            hints={hints}
            isMobile={isMobile}
            isQuestionLoading={isQuestionLoading}
            isGeneralAssemblyMvp={isGeneralAssemblyMvp}
          />
        </TabPanel>

        {!isGeneralAssemblyMvp && (
          <TabPanel>
            <Solution solutionText={question.solutionExplanation} />
          </TabPanel>
        )}

        {!isGeneralAssemblyMvp && (
          <TabPanel>
            {areVotesFetched && currentComments ? (
              <DiscussionTab
                questionId={question.id}
                questionCategory={question.category}
                currentReplies={currentReplies}
                comments={commentsValidated}
                myComments={myCommentsValidated}
                filteredComments={filteredComments}
                commentsFilter={commentsFilter}
                setCommentsFilter={setCommentsFilter}
                hasMoreComments={hasMoreComments}
                votes={votes}
                isLoading={isTextQuestionLoading}
                onCommentAdd={onCommentAdd}
                onCommentDelete={onCommentDelete}
                onVoteAdd={onVoteAdd}
                isLoadingComments={isLoadingComments}
              />
            ) : (
              <Loader />
            )}
            {user && <div ref={commentSectionBottom} />}
          </TabPanel>
        )}

        {isCodeQuestion && (
          <TabPanel>
            {areSubmissionsFetched ? (
              <Submissions
                isTableLoading={isSubmitTableLoading}
                error={submitTableError}
                submitResult={submitResult}
                submissions={submissions}
                company={question.company}
                category={question.category}
                isMobile={isMobile}
                submissionsContainerClassName={styles.submissionsContainer}
                isGeneralAssemblyMvp={isGeneralAssemblyMvp}
              />
            ) : (
              <Loader />
            )}
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

export default QuestionTabs;
