import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { LinkedInIcon, ShareIcon, TwitterIcon } from '@/assets/icons';
import { Logger } from '@/services';
import { getReferralQuery, getSharingLinks } from '@/utils';
import { useUser } from '@/hooks';
import { ShareButton } from './ShareButton';
import styles from './Share.module.scss';

interface ShareProps {
  url: string;
  text?: string;
  hashtags?: string[];
  isMobile: boolean;
}

const Share: FC<ShareProps> = (props) => {
  const { text, url, hashtags, isMobile } = props;
  const [isMobileSharing, setIsMobileSharing] = useState(isMobile);
  const { user } = useUser();
  const referralCode = useMemo(() => user?.referralCode, [user]);
  const { twitterUrl, linkedInUrl } = getSharingLinks({ text, url, hashtags, referralCode });

  const handleNativeShare = useCallback(async () => {
    if (!navigator?.share) {
      return;
    }

    try {
      await navigator.share({
        url: `${url}${getReferralQuery(referralCode, isMobile)}`,
        text,
      });
    } catch (error) {
      Logger.error(error);
    }
  }, [url, text, referralCode, isMobile]);

  useEffect(() => {
    setIsMobileSharing(isMobile && Boolean(navigator?.share));
  }, [isMobile]);

  if (isMobileSharing) {
    return (
      <Flex>
        <ShareButton
          className={styles.button}
          icon={<ShareIcon className={styles.icon} color="currentColor" />}
          text="Share"
          onClick={handleNativeShare}
        />
      </Flex>
    );
  }

  return (
    <Flex flexWrap="wrap">
      <ShareButton
        className={styles.button}
        url={twitterUrl}
        icon={<TwitterIcon className={styles.icon} />}
        text="Share on Twitter"
      />
      {!isMobile && (
        <ShareButton
          className={styles.button}
          url={linkedInUrl}
          icon={<LinkedInIcon className={styles.icon} />}
          text="Share on LinkedIn"
        />
      )}
    </Flex>
  );
};

export default Share;
