import { ChangeEvent, FC, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { scroller } from 'react-scroll';
import { useRouter } from 'next/router';
import { Box, Button, Container, Flex, Input, ScaleFade, useDisclosure, useToast } from '@chakra-ui/react';
import { useUser } from '@/hooks';
import { Comment, Question, QuestionsProps, Submission } from '@/types/pages/questions';
import { LoadingState } from '@/types';
import {
  AccessGroup,
  BONUS_QUESTIONS_ANCHOR,
  Difficulty,
  MAP_QUESTION_CATEGORY_TO_TEXT,
  Routes,
  QuestionCategory,
  QuestionStatus,
  QuestionStatusFilter,
} from '@/constants';
import { CommentService, Logger, QuestionService, SubmissionService } from '@/services';
import Layout from '@/components/layout';
import { BonusQuestions, LoadingToast, LoginModal, ReferralSection } from '@/components/common';
import {
  MenuFilterCategory,
  MenuFilterDifficulty,
  MenuFilterStatus,
  ProgressSection,
  QuestionsHeading,
  QuestionsTable,
  TagsSection,
} from '@/components/pages/questions';
import { ClearFilterIcon } from '@/assets/icons';
import { intersection } from '@/utils';
import { RequestOptions } from '@/types/api';
import { useQuestionsStore } from '@/stores';

interface QuestionsListProps extends QuestionsProps {
  questionsCategory?: QuestionCategory;
  questionOptions?: RequestOptions;
  shouldShowTags?: boolean;
  isCommonQuestionsPage?: boolean;
  isGeneralAssemblyMvp?: boolean;
}

const BONUS_QUESTION_FIELDS = ['id', 'slug', 'title', 'difficulty', 'company', 'sort', 'category', 'access_groups'];
const TOAST_ID = 'bonusQuestionsLoadingToast';
const loadingState = {
  loading: true,
  fetched: false,
};

const GetQuestionsList: FC<QuestionsListProps> = (props) => {
  const {
    questions,
    selectedFilterParams,
    questionsCategory = QuestionCategory.SQL,
    questionOptions,
    tags,
    shouldShowTags = true,
    isMobile,
    isGeneralAssemblyMvp,
    isCommonQuestionsPage,
  } = props;

  const { company, category, difficulty, status } = selectedFilterParams;
  const categoryTitle = MAP_QUESTION_CATEGORY_TO_TEXT[questionsCategory];
  const pageTitle = `${categoryTitle} Interview Questions | DataLemur`;
  const pageSeoDescription = `Practice real ${categoryTitle} Interview Questions asked by Amazon, Google, Facebook (Meta), and other FAANG companies during Data Science and Data Analyst interviews.`;
  const scrollOffset = isMobile ? -80 : -77;

  const toast = useToast();
  const { asPath, push } = useRouter();
  const isRedirectToBonusQuestions = asPath.includes(BONUS_QUESTIONS_ANCHOR);
  const { user, isAuthInitialized } = useUser();
  const { isOpen: isOpenModal, onOpen: onOpenModal, onClose } = useDisclosure();
  const { setFilterParams } = useQuestionsStore();

  const [
    { fetched: areBonusQuestionsFetched, data: bonusQuestions, loading: areBonusQuestionsLoading },
    setBonusQuestionsState,
  ] = useState<LoadingState<Question[]>>({
    loading: false,
    fetched: true,
    data: [],
  });
  const [{ fetched: areSubmissionsFetched, data: submissions }, setSubmissionsState] = useState<
    LoadingState<Submission[]>
  >({
    loading: false,
    fetched: true,
    data: [],
  });
  const [{ fetched: areCommentsFetched, data: comments }, setCommentsState] = useState<LoadingState<Comment[]>>({
    loading: false,
    fetched: true,
    data: [],
  });

  const [filterInputValue, setFilterInputValue] = useState<string>(() => company || '');
  const [filterDifficulty, setFilterDifficulty] = useState<Difficulty | null>(() => difficulty || null);
  const [filterCategory, setFilterCategory] = useState<QuestionCategory | null>(() => category || null);
  const [filterStatus, setFilterStatus] = useState<QuestionStatusFilter | null>(() => status || null);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const currentQuestions = useMemo(
    () =>
      user ? questions : questions.filter((question) => !question.accessGroups?.includes(AccessGroup.PremiumQuestions)),
    [questions, user],
  );
  const referralBonusQuestions = useMemo(
    () =>
      bonusQuestions.filter((bonusQuestion) => !bonusQuestion.accessGroups?.includes(AccessGroup.SQLSeptemberGiveaway)),
    [bonusQuestions],
  );
  const septemberGiveawayQuestions = useMemo(
    () =>
      user?.accessGroups?.includes(AccessGroup.SQLSeptemberGiveaway)
        ? bonusQuestions.filter((bonusQuestion) =>
            bonusQuestion.accessGroups?.includes(AccessGroup.SQLSeptemberGiveaway),
          )
        : [],
    [user, bonusQuestions],
  );

  const shouldDisplayBonusQuestionsSection = areBonusQuestionsFetched && Boolean(bonusQuestions.length);
  const shouldDisplayReferralQuestions = areBonusQuestionsFetched && Boolean(referralBonusQuestions.length);

  const relatedTagAndQuestionsIds = useMemo(() => {
    const init: Record<string, number[]> = {};
    tags.map((tag) => {
      const objKey = tag.id;
      init[objKey] = currentQuestions
        .filter((question) => question.tags?.some((questionTags) => questionTags.questionTagsId === tag.id))
        .map((question) => question.id);
    });
    return init;
  }, [tags, currentQuestions]);

  const tagsWithQuestionsAmount = useMemo(
    () => tags.map((tag) => ({ ...tag, questionsAmount: relatedTagAndQuestionsIds[tag.id]?.length || 0 })),
    [tags, relatedTagAndQuestionsIds],
  );

  const intersectedQuestionIds = useMemo(() => {
    const selectedQuestionIds = selectedTagIds.map((tagId) => relatedTagAndQuestionsIds[tagId]);
    return intersection(selectedQuestionIds);
  }, [selectedTagIds, relatedTagAndQuestionsIds]);

  const isLoadingSubmissions = !areSubmissionsFetched && !areCommentsFetched;
  const isProgressSectionLoading =
    (isLoadingSubmissions && !areBonusQuestionsFetched) || (!bonusQuestions.length && areBonusQuestionsLoading);
  const userId = user?.id;

  const progressBarQuestions = useMemo(
    () => (isProgressSectionLoading ? [] : [...currentQuestions, ...bonusQuestions]),
    [currentQuestions, bonusQuestions, isProgressSectionLoading],
  );

  const getQuestionFilterStatus = useCallback(
    (questionId: number): QuestionStatusFilter | null => {
      const submission = submissions.find((el) => el.questionId === questionId);

      if (!submission) return QuestionStatusFilter.NotStarted;

      switch (submission.status) {
        case QuestionStatus.Wrong:
          return QuestionStatusFilter.Error;
        case QuestionStatus.Mismatched:
          return QuestionStatusFilter.Error;
        case QuestionStatus.Solved:
          return QuestionStatusFilter.Solved;
        default:
          return null;
      }
    },
    [submissions],
  );

  const filteredQuestions: Question[] = useMemo(
    () =>
      currentQuestions.filter((question) => {
        const searchString = filterInputValue?.toLocaleLowerCase().trim() || '';
        const isSearchIncluded =
          question.title?.toLocaleLowerCase().includes(searchString) ||
          question.company?.toLocaleLowerCase().includes(searchString);
        const isSelectedTagPassed = selectedTagIds.length === 0 || intersectedQuestionIds?.includes(question.id);
        const isDifficultyFilterPassed = !filterDifficulty || question.difficulty === filterDifficulty;
        const isStatusFilterPassed = !filterStatus || getQuestionFilterStatus(question.id) === filterStatus;
        const isCategoryFilterPassed = !filterCategory || question.category === filterCategory;

        return (
          isSearchIncluded &&
          isDifficultyFilterPassed &&
          isStatusFilterPassed &&
          isSelectedTagPassed &&
          isCategoryFilterPassed
        );
      }),
    [
      currentQuestions,
      filterInputValue,
      filterDifficulty,
      filterStatus,
      filterCategory,
      getQuestionFilterStatus,
      intersectedQuestionIds,
      selectedTagIds.length,
    ],
  );

  const handleFilterInputChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setFilterInputValue(event.target.value);
  }, []);

  const scrollToBonusQuestions = useCallback(
    () =>
      scroller.scrollTo(BONUS_QUESTIONS_ANCHOR, {
        duration: 1000,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: scrollOffset,
      }),
    [scrollOffset],
  );

  const resetFilters = async () => {
    await push({}, undefined, { shallow: true });

    setFilterParams(null);
    setFilterInputValue('');
    setFilterCategory(null);
    setFilterDifficulty(null);
    setFilterStatus(null);
  };

  useEffect(() => {
    if (!isAuthInitialized || !userId) {
      return;
    }

    // make requests only if userId exists
    (async () => {
      setCommentsState((prevState) => ({
        ...prevState,
        ...loadingState,
      }));
      setSubmissionsState((prevState) => ({
        ...prevState,
        ...loadingState,
      }));

      try {
        const [fetchedSubmissions, fetchedComments] = await Promise.all([
          SubmissionService.getLatestSubmissionForEachQuestion(),
          CommentService.getComments<Comment>({
            fields: ['id', 'question_id'],
            filter: {
              deleted_at: { _null: true },
              user_id: { _eq: userId },
            },
          }),
        ]);

        setCommentsState({
          loading: false,
          fetched: true,
          data: fetchedComments,
        });

        setSubmissionsState({
          loading: false,
          fetched: true,
          data: fetchedSubmissions,
        });
      } catch (error) {
        Logger.error(error);

        setCommentsState({
          loading: false,
          fetched: true,
          data: [],
        });

        setSubmissionsState({
          loading: false,
          fetched: true,
          data: [],
        });
      }

      if (!user?.accessGroups || !user?.accessGroups.length) {
        return;
      }

      setBonusQuestionsState((prevState) => ({
        ...prevState,
        ...loadingState,
      }));

      try {
        const bonusUserQuestions = await QuestionService.getUserQuestions({
          ...questionOptions,
          fields: BONUS_QUESTION_FIELDS,
        });

        setBonusQuestionsState({ loading: false, fetched: true, data: bonusUserQuestions });
      } catch (error) {
        Logger.error(error);

        setBonusQuestionsState({ loading: false, fetched: true, data: [] });
      }
    })();
  }, [isAuthInitialized, questionOptions, user?.accessGroups, userId]);

  useEffect(() => {
    if (isRedirectToBonusQuestions && !toast.isActive(TOAST_ID)) {
      toast({
        position: 'bottom-right',
        isClosable: true,
        variant: 'top-accent',
        render(): ReactNode {
          return <LoadingToast title="Bonus Questions are loading" />;
        },
      });
    }
    if (isRedirectToBonusQuestions && shouldDisplayReferralQuestions) {
      push(Routes.Questions);
      scrollToBonusQuestions();
      toast.closeAll();
    }
  }, [shouldDisplayReferralQuestions, isRedirectToBonusQuestions, push, scrollToBonusQuestions, toast]);

  return (
    <Layout tabTitle={pageTitle} tabDescription={pageSeoDescription} isGeneralAssemblyMvp={isGeneralAssemblyMvp}>
      <Container maxWidth="1350px" py="10px" display="flex">
        <Box w="100%">
          <LoginModal isModalOpen={isOpenModal} onClose={onClose} />
          <QuestionsHeading
            questionsCategory={questionsCategory}
            isCommonQuestionsPage={isCommonQuestionsPage}
            isGeneralAssemblyMvp={isGeneralAssemblyMvp}
            isMobile={isMobile}
          />
          <Flex flexWrap={{ base: 'wrap', md: 'nowrap' }}>
            <Flex flexDir={{ base: 'column', sm: 'row' }} w={{ base: '100%', sm: 'auto' }}>
              {isCommonQuestionsPage && (
                <MenuFilterCategory filterCategory={filterCategory} setFilterCategory={setFilterCategory} />
              )}
              <MenuFilterDifficulty filterDifficulty={filterDifficulty} setFilterDifficulty={setFilterDifficulty} />
              {user && (
                <MenuFilterStatus
                  filterStatus={filterStatus}
                  setFilterStatus={setFilterStatus}
                  isOpen={Boolean(user) && undefined}
                  onOpen={user ? undefined : onOpenModal}
                  isDisabled={isLoadingSubmissions}
                />
              )}
            </Flex>
            <Flex w="100%" mt={{ base: '8px', md: '0px' }}>
              <Button
                bg="gray.200"
                p="0"
                mr="8px"
                _hover={{ backgroundColor: 'gray.300' }}
                _active={{ backgroundColor: 'gray.400' }}
                onClick={resetFilters}
              >
                <ClearFilterIcon />
              </Button>
              <Input
                placeholder="Search questions/companies"
                _placeholder={{ color: 'black.300' }}
                value={filterInputValue}
                onChange={handleFilterInputChange}
              />
            </Flex>
          </Flex>
          <QuestionsTable
            questions={filteredQuestions}
            comments={comments}
            submissions={submissions}
            isLoadingSubmissions={isLoadingSubmissions}
            isCommonQuestionsPage={isCommonQuestionsPage}
            isGeneralAssemblyMvp={isGeneralAssemblyMvp}
          />
          {user && !isGeneralAssemblyMvp && (
            <ScaleFade in={Boolean(user)}>
              <Box mt="20px">
                <ReferralSection shouldScrollBelowToSeeQuestions={true} />
                <ScaleFade in={shouldDisplayBonusQuestionsSection}>
                  {shouldDisplayReferralQuestions ? (
                    <BonusQuestions
                      questions={referralBonusQuestions}
                      comments={comments}
                      submissions={submissions}
                      isLoadingSubmissions={isLoadingSubmissions}
                      bonusQuestionsId={BONUS_QUESTIONS_ANCHOR}
                    />
                  ) : null}
                  {septemberGiveawayQuestions.length ? (
                    <BonusQuestions
                      title="SQL September Questions"
                      questions={septemberGiveawayQuestions}
                      comments={comments}
                      submissions={submissions}
                      isSubtitleVisible
                    />
                  ) : null}
                </ScaleFade>
              </Box>
            </ScaleFade>
          )}
        </Box>
        {!isMobile && (
          <Box sx={{ mt: '33px', display: 'flex', flexDir: 'column', pl: '16px', ml: '16px' }}>
            <ProgressSection
              questions={progressBarQuestions}
              submissions={submissions}
              comments={comments}
              isDataLoading={isProgressSectionLoading}
            />
            {shouldShowTags && (
              <TagsSection
                tags={tagsWithQuestionsAmount}
                selectedTagIds={selectedTagIds}
                setSelectedTagIds={setSelectedTagIds}
              />
            )}
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default memo(GetQuestionsList);
