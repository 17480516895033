import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Wrap } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { CommentsFilter, QuestionCategory } from '@/constants';
import { debounce, getDefaultCommentText } from '@/utils';
import { MarkdownEditorCustom } from '@/components/common';

interface NewCommentInputProps {
  questionCategory: QuestionCategory;
  isCommentSubmitting: boolean;
  questionId: number;
  commentsFilter: CommentsFilter;
  setCommentsFilter: (value: CommentsFilter) => void;
  isLoading: boolean;
  handleSubmitComment: (commentValue: string, parentId?: string) => void;
}

const NewCommentInput: FC<NewCommentInputProps> = (props) => {
  const {
    questionCategory,
    isCommentSubmitting,
    questionId,
    commentsFilter,
    setCommentsFilter,
    isLoading,
    handleSubmitComment,
  } = props;
  const defaultCommentText = useMemo(() => getDefaultCommentText(questionCategory), [questionCategory]);
  const [mdValue, setMdValue] = useState<string>(defaultCommentText);
  const commentValueCacheKey = `comment-input-${questionId}`;

  const saveCommentValueToCache = useMemo(
    () =>
      debounce((value: string) => {
        localStorage.setItem(commentValueCacheKey, value);
      }, 1000),
    [commentValueCacheKey],
  );

  const handleCommentInputChange = useCallback(
    (newVal?: string): void => {
      if (typeof newVal === 'string') {
        setMdValue(newVal);
        saveCommentValueToCache(newVal);
      }
    },
    [saveCommentValueToCache],
  );

  const handleSubmit = useCallback(async () => {
    await handleSubmitComment(mdValue);

    setMdValue(defaultCommentText);
    localStorage.removeItem(commentValueCacheKey);
  }, [mdValue, commentValueCacheKey, defaultCommentText, handleSubmitComment]);

  useEffect(() => {
    const savedCommentInput = localStorage.getItem(commentValueCacheKey);

    if (savedCommentInput) {
      setMdValue(savedCommentInput);
    }
  }, [setMdValue, commentValueCacheKey]);

  return (
    <>
      <Wrap p="5px" w="100%">
        <MarkdownEditorCustom value={mdValue} onChange={handleCommentInputChange} />
      </Wrap>
      <Flex flexDir={{ base: 'column-reverse', sm: 'row' }} justifyContent="space-between" mt="10px">
        <Menu>
          {({ isOpen: isMenuOpen }) => (
            <>
              <MenuButton
                bg="gray.200"
                mt={{ base: '10px', sm: '0' }}
                w={{ base: '100%', sm: '200px' }}
                py="6px"
                _hover={{ bg: 'gray.300', _active: { bg: 'gray.400' } }}
                isActive={isMenuOpen}
                as={Button}
                rightIcon={<ChevronDownIcon />}
                disabled={isCommentSubmitting}
              >
                {commentsFilter}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setCommentsFilter(CommentsFilter.MostVotes)}>
                  {CommentsFilter.MostVotes}
                </MenuItem>
                <MenuItem onClick={() => setCommentsFilter(CommentsFilter.NewestToOldest)}>
                  {CommentsFilter.NewestToOldest}
                </MenuItem>
                <MenuItem onClick={() => setCommentsFilter(CommentsFilter.OldestToNewest)}>
                  {CommentsFilter.OldestToNewest}
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
        <Button
          isDisabled={!mdValue.trim().length}
          onClick={handleSubmit}
          colorScheme="red"
          variant="outline"
          isLoading={isLoading || isCommentSubmitting}
          loadingText="Submitting..."
        >
          Submit
        </Button>
      </Flex>
    </>
  );
};

export default NewCommentInput;
