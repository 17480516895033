import { Dispatch, FC, SetStateAction } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MAP_QUESTION_CATEGORY_TO_TEXT, QuestionCategory, QuestionsFilterQueryKey } from '@/constants';
import { useQuestionsFilterQuery } from '@/hooks';

interface MenuFilterCategoryProps {
  filterCategory: QuestionCategory | null;
  setFilterCategory: Dispatch<SetStateAction<QuestionCategory | null>>;
}

const MenuFilterCategory: FC<MenuFilterCategoryProps> = (props) => {
  const { filterCategory, setFilterCategory } = props;

  const { setFilterQuery } = useQuestionsFilterQuery();

  const setFilterCategoryHandler = async (category: QuestionCategory | null) => {
    await setFilterQuery(QuestionsFilterQueryKey.Category, category);
    setFilterCategory(category);
  };

  const menuButtonMinWidth = filterCategory === QuestionCategory.MachineLearning ? '176px' : '126px';

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            bg="gray.200"
            mr={{ base: '0', sm: '8px' }}
            mt={{ base: '8px', sm: '0' }}
            minW={{ base: '100%', sm: 'auto' }}
            p="6px"
            w={menuButtonMinWidth}
            _hover={{ bg: 'gray.300', _active: { bg: 'gray.400' } }}
            isActive={isOpen}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {(filterCategory && MAP_QUESTION_CATEGORY_TO_TEXT[filterCategory]) || 'Category'}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setFilterCategoryHandler(null)}>All</MenuItem>
            <MenuItem onClick={() => setFilterCategoryHandler(QuestionCategory.SQL)}>
              {MAP_QUESTION_CATEGORY_TO_TEXT[QuestionCategory.SQL]}
            </MenuItem>
            <MenuItem onClick={() => setFilterCategoryHandler(QuestionCategory.Statistics)}>
              {MAP_QUESTION_CATEGORY_TO_TEXT[QuestionCategory.Statistics]}
            </MenuItem>
            <MenuItem onClick={() => setFilterCategoryHandler(QuestionCategory.MachineLearning)}>
              {MAP_QUESTION_CATEGORY_TO_TEXT[QuestionCategory.MachineLearning]}
            </MenuItem>
            <MenuItem onClick={() => setFilterCategoryHandler(QuestionCategory.Probability)}>
              {MAP_QUESTION_CATEGORY_TO_TEXT[QuestionCategory.Probability]}
            </MenuItem>
            <MenuItem onClick={() => setFilterCategoryHandler(QuestionCategory.Python)}>
              {MAP_QUESTION_CATEGORY_TO_TEXT[QuestionCategory.Python]}
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default MenuFilterCategory;
