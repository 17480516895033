import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Flex, Text, useDisclosure, Wrap } from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Avatar, LoginModal, Markdown } from '@/components/common';
import { mockCommentMarkdown, VoteOutcome } from '@/constants';
import MarkdownEditorCustom from '@/components/common/MarkdownEditorCustom';
import { ReplyCommentInput } from '@/components/pages/question';
import ClientOnly from '@/components/common/ClientOnly';
import { COMMENT_DATE_DEFAULT, NEW_COMMENT_MD_DEFAULT } from '@/constants/defaultValues';
import { useUser } from '@/hooks';
import { Vote } from '@/types/models';
import { Comment } from '@/types/pages/question';
import { CommentService, Logger, VotesService } from '@/services';
import { getUserName } from '@/utils';
import { getRelativeCommentTime, getTriangleIconColor, TriangleDirection } from './helpers';

export interface DiscussionCommentProps extends Comment {
  onCommentDelete: (id: string) => void;
  userVote: Vote | null;
  replyComments: Comment[];
  handleSubmitComment: (commentValue: string, parentId?: string) => void;
}

export enum Operations {
  WatchOperation,
  EditOperation,
  DeleteOperation,
  VoteOperation,
  EditProcessing,
  DeleteProcessing,
  VoteProcessing,
  ReplyProcessing,
}

const DiscussionComment: FC<DiscussionCommentProps> = ({
  id: commentId,
  userId,
  user: commentUser,
  body,
  parentId,
  createdAt,
  upvotes,
  downvotes,
  userVote,
  onCommentDelete,
  replyComments,
  handleSubmitComment,
}) => {
  const { user } = useUser();
  const userName = getUserName(commentUser);
  const repliesForDisplay = useMemo(
    () => [...replyComments].sort((a, b) => b.upvotes - b.downvotes - (a.upvotes - a.downvotes)),
    [replyComments],
  );
  const [curVotes, setCurVotes] = useState<number>(() => upvotes - downvotes);
  const [curVoteStatus, setCurrentVoteStatus] = useState<VoteOutcome | null>(userVote?.outcome || null);
  const [currentOperation, setCurrentOperation] = useState<Operations>(Operations.WatchOperation);
  //used to show in current-published comment field
  const [currentComment, setCurrentComment] = useState<string>(body || mockCommentMarkdown);
  //used to keep tracking new comment value in editor
  const [newCommentValue, setNewCommentValue] = useState<string>(body || NEW_COMMENT_MD_DEFAULT);
  const [currentUserVote, setCurrentUserVote] = useState<Vote | null>(userVote);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isCommentCreator = user?.id === userId;

  const defaultReplyValue = `**@${userName}** `;
  const replyValueCacheKey = `reply-input-${commentId}`;
  const [showReplyInput, setShowReplyInput] = useState(() => Boolean(localStorage.getItem(replyValueCacheKey)));
  const relativeCommentTime = useMemo<string>(
    () => getRelativeCommentTime(createdAt ? createdAt : COMMENT_DATE_DEFAULT.toDateString()),
    [createdAt],
  );

  const handleDeleteClick = useCallback(async (): Promise<void> => {
    //Prevent multi-clicking
    if (currentOperation !== Operations.WatchOperation) {
      return;
    }
    setCurrentOperation(Operations.DeleteProcessing);
    try {
      await CommentService.deleteComment({ commentId });
      onCommentDelete(commentId);
    } catch (error) {
      Logger.error(error);
      setCurrentOperation(Operations.WatchOperation);
    }
  }, [onCommentDelete, commentId, currentOperation]);

  const handleEditSubmit = useCallback(async (): Promise<void> => {
    //Prevent multi-clicking
    if (currentOperation !== Operations.EditOperation) {
      return;
    }

    setCurrentOperation(Operations.EditProcessing);

    try {
      await CommentService.updateComment({ commentId, newCommentValue });

      setCurrentOperation(Operations.WatchOperation);
      setNewCommentValue(newCommentValue);
      setCurrentComment(newCommentValue);
    } catch (error) {
      Logger.error(error);
      setCurrentOperation(Operations.EditOperation);
    }
  }, [commentId, newCommentValue, currentOperation]);

  const voteUp = useCallback(async (): Promise<void> => {
    if (!user) {
      onOpen();
      return;
    }
    if (currentOperation === Operations.WatchOperation) {
      setCurrentOperation(Operations.VoteProcessing);

      try {
        //up: -1 -> 1
        if (curVoteStatus === VoteOutcome.Downvote && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Upvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(VoteOutcome.Upvote);
          setCurVotes((val) => val + 2);
          //unup: 1 -> 0
        } else if (curVoteStatus === VoteOutcome.Upvote && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Upvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(null);
          setCurVotes((val) => val - 1);
          //if field exists but null: 0 -> 1
        } else if (curVoteStatus === null && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Upvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(VoteOutcome.Upvote);
          setCurVotes((val) => val + 1);
          //if field doesn't exist: create(1)
        } else {
          let newVote = await VotesService.createVote({ outcome: VoteOutcome.Upvote, commentId: commentId });
          setCurrentVoteStatus(VoteOutcome.Upvote);
          setCurVotes((val) => val + 1);
          setCurrentUserVote(newVote);
        }
      } catch (error) {
        Logger.error(error);
      } finally {
        setCurrentOperation(Operations.WatchOperation);
      }
    }
  }, [curVoteStatus, currentOperation, currentUserVote, commentId, user, onOpen]);

  const voteDown = useCallback(async (): Promise<void> => {
    if (!user) {
      onOpen();
      return;
    }
    if (currentOperation === Operations.WatchOperation) {
      setCurrentOperation(Operations.VoteProcessing);

      try {
        //undown: -1 -> 0
        if (curVoteStatus === VoteOutcome.Downvote && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Downvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(null);
          setCurVotes((val) => val + 1);
          //down: 1 -> -1
        } else if (curVoteStatus === VoteOutcome.Upvote && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Downvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(VoteOutcome.Downvote);
          setCurVotes((val) => val - 2);
          //if field exists but null: 0 -> -1
        } else if (curVoteStatus === null && currentUserVote) {
          await VotesService.createVote({ outcome: VoteOutcome.Downvote, commentId: currentUserVote.commentId });
          setCurrentVoteStatus(VoteOutcome.Downvote);
          setCurVotes((val) => val - 1);
          //if field doesn't exist: create(-1)
        } else {
          let newVote = await VotesService.createVote({ outcome: VoteOutcome.Downvote, commentId });
          setCurrentVoteStatus(VoteOutcome.Downvote);
          setCurVotes((val) => val - 1);
          setCurrentUserVote(newVote);
        }
      } catch (error) {
        Logger.error(error);
      } finally {
        setCurrentOperation(Operations.WatchOperation);
      }
    }
  }, [curVoteStatus, currentOperation, commentId, currentUserVote, user, onOpen]);

  const handleEditClick = useCallback((): void => {
    if (currentOperation === Operations.EditOperation) {
      setCurrentOperation(Operations.WatchOperation);
      setNewCommentValue(body ? body : mockCommentMarkdown);
    }

    if (currentOperation === Operations.WatchOperation) {
      setCurrentOperation(Operations.EditOperation);
    }
  }, [body, currentOperation]);

  const handleCommentEditorChange = useCallback((newVal: string | undefined): void => {
    setNewCommentValue(newVal || '');
  }, []);

  const clearReplyCache = useCallback(() => {
    localStorage.removeItem(replyValueCacheKey);
  }, [replyValueCacheKey]);

  const handleReply = () => {
    if (currentOperation === Operations.ReplyProcessing) {
      return;
    }
    if (showReplyInput) {
      clearReplyCache();
    }

    setShowReplyInput((prevState) => !prevState);
  };

  const handleReplySubmit = useCallback(
    async (replyCommentValue: string): Promise<void> => {
      setCurrentOperation(Operations.ReplyProcessing);
      try {
        await handleSubmitComment(replyCommentValue, commentId);
        clearReplyCache();
      } catch (error) {
        Logger.error(error);
      } finally {
        setCurrentOperation(Operations.WatchOperation);
        setShowReplyInput(false);
      }
    },
    [commentId, handleSubmitComment, clearReplyCache],
  );

  useEffect(() => {
    setCurrentVoteStatus(userVote?.outcome || null);
    setCurrentUserVote(userVote);
  }, [userVote]);

  return (
    <>
      <Flex backgroundColor="gray.100" direction="column" p="16px" mt="10px" ml={parentId ? '20px' : '0'} bg="gray.200">
        <LoginModal isModalOpen={isOpen} onClose={onClose} />
        <Flex alignItems="center">
          <Avatar avatarId={commentUser.avatar} userName={userName} />
          <Text ml="10px" fontWeight="bold">
            {userName}
          </Text>
        </Flex>
        <Wrap marginTop="20px">
          <Markdown>{currentComment}</Markdown>
        </Wrap>
        <Flex alignItems="center" flexWrap="wrap">
          <ClientOnly>
            <Text pt="6px" mr="16px">
              {relativeCommentTime}
            </Text>
          </ClientOnly>
          {/* votes */}
          <Flex alignItems="center" pt="6px">
            <TriangleUpIcon
              color={getTriangleIconColor(curVoteStatus, TriangleDirection.Up)}
              _hover={{ cursor: 'pointer' }}
              onClick={voteUp}
            />
            <Text ml="6px">{curVotes}</Text>
            <TriangleDownIcon
              color={getTriangleIconColor(curVoteStatus, TriangleDirection.Down)}
              ml="6px"
              mr="16px"
              _hover={{ cursor: 'pointer' }}
              onClick={voteDown}
            />
            {!parentId && (
              <Text
                _hover={{
                  textDecoration: 'underline',
                  cursor: currentOperation === Operations.ReplyProcessing ? 'not-allowed' : 'pointer',
                }}
                mr="16px"
                onClick={handleReply}
              >
                {showReplyInput ? 'Discard reply' : 'Reply'}
              </Text>
            )}
          </Flex>

          {isCommentCreator && (
            <Flex pt="6px">
              <Button
                colorScheme="red"
                variant="outline"
                p="4px"
                h="fit-content"
                onClick={handleEditClick}
                isDisabled={![Operations.WatchOperation, Operations.EditOperation].includes(currentOperation)}
                _hover={{ cursor: 'pointer' }}
              >
                {currentOperation === Operations.EditOperation || currentOperation === Operations.EditProcessing
                  ? 'Discard changes'
                  : 'Edit'}
              </Button>
              {![Operations.EditOperation, Operations.EditProcessing].includes(currentOperation) && (
                <Button
                  colorScheme="red"
                  variant="outline"
                  ml="10px"
                  p="4px"
                  h="fit-content"
                  onClick={handleDeleteClick}
                  isLoading={currentOperation === Operations.DeleteProcessing}
                  isDisabled={currentOperation !== Operations.WatchOperation}
                  loadingText="Deleting..."
                  _hover={{ cursor: 'pointer' }}
                >
                  Delete
                </Button>
              )}
            </Flex>
          )}
        </Flex>

        {(currentOperation === Operations.EditOperation || currentOperation === Operations.EditProcessing) && (
          <Flex marginTop="16px" direction="column" alignItems="flex-start" gap="10px">
            <MarkdownEditorCustom value={newCommentValue} onChange={handleCommentEditorChange} />
            <Button
              isDisabled={!newCommentValue.trim().length}
              onClick={handleEditSubmit}
              colorScheme="red"
              variant="outline"
              marginTop="10px"
              isLoading={currentOperation === Operations.EditProcessing}
              loadingText="Submitting..."
            >
              Submit
            </Button>
          </Flex>
        )}
      </Flex>
      {showReplyInput && (
        <ReplyCommentInput
          defaultReplyValue={defaultReplyValue}
          handleReplySubmit={handleReplySubmit}
          currentOperation={currentOperation}
          replyValueCacheKey={replyValueCacheKey}
        />
      )}
      {repliesForDisplay.map((reply) => (
        <DiscussionComment
          key={reply.id}
          onCommentDelete={onCommentDelete}
          userVote={userVote}
          replyComments={[]}
          handleSubmitComment={handleSubmitComment}
          {...reply}
        />
      ))}
    </>
  );
};

export default DiscussionComment;
