import { Dispatch, FC, SetStateAction } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Difficulty, QuestionsFilterQueryKey } from '@/constants';
import { useQuestionsFilterQuery } from '@/hooks';

interface MenuFilterDifficultyProps {
  filterDifficulty: Difficulty | null;
  setFilterDifficulty: Dispatch<SetStateAction<Difficulty | null>>;
}

const MenuFilterDifficulty: FC<MenuFilterDifficultyProps> = (props) => {
  const { filterDifficulty, setFilterDifficulty } = props;

  const { setFilterQuery } = useQuestionsFilterQuery();

  const setFilterDifficultyHandler = async (category: Difficulty | null) => {
    await setFilterQuery(QuestionsFilterQueryKey.Difficulty, category);
    setFilterDifficulty(category);
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            bg="gray.200"
            mr={{ base: '0', sm: '8px' }}
            mt={{ base: '8px', sm: '0' }}
            minW={{ base: '100%', sm: 'auto' }}
            p="6px"
            w="126px"
            _hover={{ bg: 'gray.300', _active: { bg: 'gray.400' } }}
            isActive={isOpen}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {filterDifficulty || 'Difficulty'}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setFilterDifficultyHandler(null)}>All</MenuItem>
            <MenuItem onClick={() => setFilterDifficultyHandler(Difficulty.Easy)}>{Difficulty.Easy}</MenuItem>
            <MenuItem onClick={() => setFilterDifficultyHandler(Difficulty.Medium)}>{Difficulty.Medium}</MenuItem>
            <MenuItem onClick={() => setFilterDifficultyHandler(Difficulty.Hard)}>{Difficulty.Hard}</MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default MenuFilterDifficulty;
