import { FC } from 'react';
import { Box, Spinner, Td, Text, Tr } from '@chakra-ui/react';
import { CheckIcon, CloseIcon, MinusIcon } from '@chakra-ui/icons';
import { AccessGroup, Color, MAP_QUESTION_CATEGORY_TO_TEXT, QuestionStatus } from '@/constants';
import { useUser } from '@/hooks';
import { Question } from '@/types/pages/questions';
import Companies from '@/components/common/CompanyTag';
import { TableLink } from '@/components/pages/question';
import { Question as QuestionHome } from '@/types/pages/home';
import { getDifficultyColor } from './helpers';

interface QuestionItemProps {
  question: Question | QuestionHome;
  status?: QuestionStatus | null;
  isLoading?: boolean;
  isFullTable?: boolean;
  isCommonQuestionsPage?: boolean;
  isGeneralAssemblyMvp?: boolean;
  onClick?: () => void;
}

const QuestionRowItem: FC<QuestionItemProps> = (props) => {
  const {
    question,
    status,
    isLoading = false,
    isFullTable = true,
    isCommonQuestionsPage,
    isGeneralAssemblyMvp,
    onClick,
  } = props;
  const { user } = useUser();

  const renderSubmissionStatusIcon = () => {
    if (isLoading) {
      return <Spinner />;
    }
    switch (status) {
      case QuestionStatus.Solved:
        return <CheckIcon color="green" />;
      case QuestionStatus.Wrong || QuestionStatus.Mismatched:
        return <CloseIcon color="red" />;
      default:
        return <MinusIcon alignSelf="center" />;
    }
  };

  const isPremiumQuestion = question.accessGroups?.includes(AccessGroup.PremiumQuestions);

  const isUnlockedQuestion = isPremiumQuestion && user?.accessGroups?.includes(AccessGroup.PremiumQuestions);

  return (
    <Tr
      _hover={{
        color: 'black.300',
      }}
      onClick={onClick}
    >
      {!isGeneralAssemblyMvp && (
        <Td p="0" h="54px" maxWidth="140px" color="black.100">
          <TableLink questionSlug={question.slug}>
            {question.company && <Companies companies={question.company} />}
          </TableLink>
        </Td>
      )}
      <Td p="0" h="54px" maxWidth="270px">
        <TableLink questionSlug={question.slug} isGeneralAssemblyMvp={isGeneralAssemblyMvp}>
          <Text isTruncated>{question.title}</Text>
          {isPremiumQuestion && (
            <Box ml="6px" display="flex">
              <Text color="black" fontSize="12px" borderRadius="6px" px="5px" backgroundColor={Color.PremiumLightRed}>
                Premium
                {!isUnlockedQuestion && (
                  <Text as="span" ml="4px">
                    🔒
                  </Text>
                )}
              </Text>
            </Box>
          )}
        </TableLink>
      </Td>
      {isCommonQuestionsPage && (
        <Td p="0" h="54px">
          <TableLink questionSlug={question.slug}>{MAP_QUESTION_CATEGORY_TO_TEXT[question.category]}</TableLink>
        </Td>
      )}
      <Td p="0" h="54px" color={getDifficultyColor(question.difficulty)}>
        <TableLink questionSlug={question.slug}>{question.difficulty} </TableLink>
      </Td>
      {isFullTable && user && (
        <Td p="0" h="54px" maxWidth="40px">
          <TableLink questionSlug={question.slug} isCentered={true}>
            {renderSubmissionStatusIcon()}
          </TableLink>
        </Td>
      )}
    </Tr>
  );
};

export default QuestionRowItem;
