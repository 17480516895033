import { FC } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { QUESTION_TITLE_DEFAULT } from '@/constants/defaultValues';

interface QuestionHeaderProps {
  title: string | null;
}

const QuestionHeader: FC<QuestionHeaderProps> = ({ title }) => (
  <Flex alignItems="center" flexShrink={0}>
    <Heading as="h1" my="4px" fontWeight="bold" variant="main">
      {title ? title : QUESTION_TITLE_DEFAULT}
    </Heading>
  </Flex>
);

export default QuestionHeader;
