import { FC, useCallback, useMemo } from 'react';
import Link from 'next/link';
import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Box, Center, Divider, Flex, Heading, Text, useTheme } from '@chakra-ui/react';
import { Comment, Question, Submission } from '@/types/pages/questions';
import { useUser } from '@/hooks';
import { Routes } from '@/constants';
import { getDifficultyColor } from '@/components/pages/questions/QuestionRowItem/helpers';
import { AchievementItem as AchievementItemType } from '@/types';
import { getAchievements } from '@/utils';
import { Loader } from '@/components/common';
import 'react-circular-progressbar/dist/styles.css';

const DEFAULT_PROGRESS_BAR_VALUE = 1;

interface ProgressSectionsProps {
  questions: Question[];
  comments: Comment[];
  submissions: Submission[];
  isDataLoading: boolean;
}

const AchievementItem: FC<AchievementItemType> = (props) => {
  const { difficulty, solvedAmount, textBasedQuestionsIds, codeQuestionsIds } = props;
  const questionsAmount = textBasedQuestionsIds.length + codeQuestionsIds.length;

  return (
    <Flex w="100%" justifyContent="space-between">
      <Flex alignItems="center">
        <Text w="10px" h="10px" backgroundColor={getDifficultyColor(difficulty)} borderRadius="5px" mr="10px" />
        <Text pb="2px">{difficulty}</Text>
      </Flex>
      <Text alignSelf="flex-end">
        {solvedAmount}/{questionsAmount}
      </Text>
    </Flex>
  );
};

const ProgressSection: FC<ProgressSectionsProps> = (props) => {
  const { questions, comments, submissions, isDataLoading } = props;
  const { user } = useUser();
  const { colors } = useTheme();
  const achievementsForDisplay = useMemo(
    (): AchievementItemType[] => getAchievements(user, questions, comments, submissions),
    [user, questions, comments, submissions],
  );

  const totalSolvedQuestions = achievementsForDisplay.reduce((acc, item) => acc + item.solvedAmount, 0);
  const totalPercentSolvedQuestions = useMemo(
    () => `${isDataLoading ? 0 : Math.ceil((totalSolvedQuestions / questions.length) * 100)}%`,
    [totalSolvedQuestions, questions.length, isDataLoading],
  );

  const getPercentOfSolvedQuestions = useCallback(
    (item: AchievementItemType): number => {
      if (!item || !user || !item.solvedAmount) {
        return DEFAULT_PROGRESS_BAR_VALUE;
      }
      const amountQuestions = item.codeQuestionsIds.length + item.textBasedQuestionsIds.length;
      return Math.ceil((item.solvedAmount / amountQuestions) * 100);
    },
    [user],
  );

  return (
    <Center flexDir="column" w="320px">
      <Heading as="h3" alignSelf="baseline" variant="secondary" mb="18px">
        Your Progress
      </Heading>
      <Center flexDir="column" p="10px" w="100%" border="1px solid" borderColor="gray.300" borderRadius="20px">
        <Box w="200px">
          <CircularProgressbarWithChildren
            value={getPercentOfSolvedQuestions(achievementsForDisplay[0])}
            text={totalPercentSolvedQuestions}
            styles={buildStyles({
              pathColor: colors.green[200],
              textColor: colors.red[500],
              trailColor: colors.green[100],
            })}
          >
            <Box w="80%">
              <CircularProgressbarWithChildren
                value={getPercentOfSolvedQuestions(achievementsForDisplay[1])}
                styles={buildStyles({
                  pathColor: colors.orange[200],
                  trailColor: colors.orange[100],
                })}
              >
                <Box w="80%">
                  <CircularProgressbar
                    value={getPercentOfSolvedQuestions(achievementsForDisplay[2])}
                    styles={buildStyles({
                      pathColor: colors.red[200],
                      trailColor: colors.red[100],
                    })}
                  />
                </Box>
              </CircularProgressbarWithChildren>
            </Box>
          </CircularProgressbarWithChildren>
        </Box>
        <Divider mt="10px" borderColor="gray.400" w="100%" />
        <>
          {!isDataLoading ? (
            <Flex w="100%" flexDir="column" justifyContent="flex-start" my="8px">
              {achievementsForDisplay.map((item, index) => (
                <AchievementItem key={index} {...item} />
              ))}
            </Flex>
          ) : (
            <Loader height="94px" />
          )}
        </>
        {user && (
          <Link href={Routes.ProfilePerformance} passHref legacyBehavior>
            <Text textDecoration="underline" cursor="pointer">
              Refer Friends To Unlock More Questions
            </Text>
          </Link>
        )}
      </Center>
    </Center>
  );
};

export default ProgressSection;
